import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import '@evag/ec-react/dist/main.css';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function render({data}) {
    return (
        <Layout>
            <SEO title="Política de Privacidade"/>

            <main className="main">
                <div className="wrap">
                    <h1 className="title">Política de privacidade</h1>
                        <div>
                            <p>Última atualização: 15/04/2021</p>


                            <p>Seus dados são importantes e nós fazemos tudo que podemos para protegê-los e usá-los de
                                acordo com o consentimento que você nos dá ao fornecê-los. Para isso buscamos deixar
                                claro aqui quais dados coletamos e o que fazemos com eles. Sendo assim, quando você usa
                                o nosso site assumimos que está de acordo com os termos expressados aqui.</p>


                            <p>Se você tiver menos de 16 anos, por favor, peça o consentimento de uma pessoa legalmente
                                responsável por você antes de colocar suas informações pessoais neste site. Caso
                                tenhamos motivos para acreditar que alguém é menor de idade e que coletamos suas
                                informações pessoais sem o consentimento adequado, deletaremos tais informações em um
                                período de tempo razoável.</p>


                            <p>Podemos atualizar essa política periodicamente. Quando isso for feito, incluiremos um
                                aviso no nosso site destacando o que foi alterado. O seu uso continuado do site após a
                                postagem das alterações significará que você aceitou e concordou com as alterações.</p>


                            <h3>Para quê coletamos dados e como são usados?</h3>


                            <p>Acreditamos que política se faz com participação e mobilização. E para isso precisamos da
                                boas ferramentas de comunicação. É isso que buscamos através da coleta de dados. Podemos
                                citar então os seguintes propósitos gerais e, dentro deles, alguns mais específicos:</p>


                            <p>1. Divulgação e mobilização.</p>


                            <ul>
                                <li>Entrar em contato por e-mail ou telefone para divulgar nossas ações.</li>
                                <li>Enviar materiais pelo correio.</li>
                                <li>Buscar melhorias na efetividade de nossas campanhas.</li>
                            </ul>


                            <p>2. Monitoramento e aprimoramento do site.</p>


                            <ul>
                                <li>Saber quais páginas são mais visitadas e através de quais tipos de navegadores e
                                    dispositivos.
                                </li>
                                <li>Proteger de ataques e melhorar a segurança das ferramentas utilizadas.</li>
                            </ul>


                            <p>3. Melhoria da nossa atuação em redes sociais.</p>


                            <ul>
                                <li>Poder direcionar conteúdos nas redes sociais para públicos que demostraram interesse
                                    pelo assunto.
                                </li>
                            </ul>


                            <p>No caso de abaixo-assinados e petições, tendo em vista que tratam do recolhimento de
                                assinaturas para pressionar alguém, consideramos que as informações dos campos nome,
                                estado e cidade são públicas. Isso permite, por exemplo, que imprimamos e entreguemos
                                uma lista de assinaturas ao alvo da ação.</p>


                            <p>Alguns formulários podem pedir explicitamente sua autorização para que usemos seus dados
                                para algo específico, por exemplo:</p>


                            <ul>
                                <li>Mandar e-mails e mensagens para o seu celular divulgando nossas ações.</li>
                            </ul>


                            <h3>Quais dados coletamos e como?</h3>


                            <p>Há duas formas usadas para coletar os dados:</p>


                            <p>1. Manual: quando você explicitamente fornece dados preenchendo formulários em nosso
                                site.</p>


                            <p>2. Automática: quando os dados são coletados diretamente do seu navegador ou conexão.</p>


                            <ul>
                                <li>Quando você navega no nosso site ele automaticamente coleta informações sobre:
                                    <ul>
                                        <li>as páginas acessadas por você neste site</li>
                                        <li>seu IP</li>
                                        <li>dados da requisição (HTTP referer)</li>
                                        <li>dados do navegador e dispositivo</li>
                                    </ul>
                                </li>
                                <li>Usamos cookies do Google Analytics e do Facebook para que possamos utilizar serviços
                                    deles, o que também permite que eles coletem dados sobre você, segundo suas próprias
                                    políticas de privacidade (listadas mais abaixo).
                                </li>
                                <li>Os e-mails que enviamos também usam rastreadores de abertura e de links, para que
                                    saibamos se eles estão sendo lidos e quais links estão sendo mais acessados.
                                </li>
                            </ul>


                            <h3>Cookies</h3>


                            <p>A coleta automática de dados utiliza os seguintes cookies:</p>


                            <ul>
                                <li>Repassados ao nosso próprio site:
                                    <ul>
                                        <li>evlt: nosso próprio sistema de monitoramento do site.</li>
                                        <li>_ga, _gid, _gat: Google Analytics.</li>
                                        <li>_fbp: Facebook.</li>
                                    </ul>
                                </li>
                                <li>Repassados a outros sites (“terceiros”):
                                    <ul>
                                        <li>evt: monitoramento através dos sites da nossa rede.</li>
                                        <li>fr: Facebook.</li>
                                    </ul>
                                </li>
                            </ul>


                            <h3>Com quem compartilhamos seus dados?</h3>


                            <p>Utilizamos os seguintes serviços, cada um com sua própria política de privacidade:</p>


                            <ul>
                                <li><a href="https://policies.google.com/privacy?hl=pt-BR">Google Analytics</a>:
                                    monitoramento e análise do acesso ao site.
                                </li>
                                <li><a href="https://pt-br.facebook.com/about/privacy/">Facebook Pixel</a>: segmentação
                                    de público no envio de conteúdos pelo Facebook.
                                </li>
                                <li><a href="https://aws.amazon.com/ses/">Amazon SES</a>: envio de e-mails.</li>
                            </ul>


                            <h3>Segurança</h3>


                            <p>Fazemos todo o possível para proteger suas informações pessoais contra perda ou usos
                                indevidos. Mas, infelizmente, nenhum sistema de transmissão ou armazenamento de dados é
                                totalmente seguro. Deste modo, não há como garantir a absoluta segurança dos dados. A
                                utilização de nosso site nessas condições é uma decisão pessoal.</p>


                            <h3>Por quanto tempo armazenamos os dados?</h3>


                            <p>Pretendemos armazenar os dados coletados enquanto avaliarmos que eles podem nos ajudar
                                nos objetivos descritos anteriormente. Mas, caso você deseje que deletemos suas
                                informações, por favor, entre em contato conosco.</p>
                        </div>
                </div>
            </main>
        </Layout>
    );
}

render.propTypes = {
    data: PropTypes.object,
    lapis: PropTypes.object
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    bancada: file(relativePath: { eq: "logo-bancada.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    
  }
`;
